import Vue from 'vue'
import VueRouter from 'vue-router'
import App from "./App.vue";

Vue.config.productionTip = false

Vue.use(VueRouter);

import Introduction from "./pages/Introduction.vue";
import AboutMe from "./pages/AboutMe.vue";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";
import TermsOfService from "./pages/TermsOfService.vue";

const routes = [
    { path: '/', component: Introduction },
    { path: '/about', component: AboutMe },
    { path: '/privacy', component: PrivacyPolicy },
    { path: '/terms', component: TermsOfService }
]

const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
});

new Vue({
    render: h => h(App),
    router
}).$mount('#poespas')
