<template>
    <div id="poespas" class="page-content">
        <Background />
        <div class="container">
            <router-view :key="$route.path" />
        </div>
    </div>
</template>

<script>
import Background from "./components/Background.vue";

export default {
    name: "App",
    components: {
        Background
    }
};
</script>

<style lang="scss">
    @import "./styles/styles.scss";
</style>